import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  getPaymentMethodFriendlyName,
  getPaymentMethodMask,
} from 'src/products/general/MakePayment/MakePayment.utils'
import {
  ActionListItem,
  ActionListItemProps,
} from 'src/products/general/components/molecules/ActionListItem/ActionListItem'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {PaymentMethodLinkDebitCardSubset} from 'src/products/general/GeneralPaymentMethods/PaymentMethodLink/PaymentMethodLink.types'

type DebitCardPaymentMethodLinkProps = Partial<ActionListItemProps> & {
  paymentMethod: PaymentMethodLinkDebitCardSubset
}

export const DebitCardPaymentMethodLink = ({
  paymentMethod,
  titleStatusPill,
  rightStatusPill,
  ...props
}: DebitCardPaymentMethodLinkProps): JSX.Element => {
  const {t} = useTranslation('MakePayment')
  const friendlyName = getPaymentMethodFriendlyName(paymentMethod) ?? ''

  const accountMask = getPaymentMethodMask(paymentMethod) ?? ''
  const subTitle = (
    <>
      <PFText variant="p">{t('DebitCardEndingIn')}</PFText>
      <PFText variant="p_semibold"> {accountMask}</PFText>
    </>
  )

  return (
    <ActionListItem
      isFirstChild
      /* Debit cards may not be associated with an account. If there is an account, add
       * 'Debit Card' to the title so it's clear this isn't an ach account */
      title={friendlyName ?? t('DebitCard')}
      subTitle={subTitle}
      titleStatusPill={titleStatusPill}
      rightStatusPill={rightStatusPill}
      {...props}
    />
  )
}
