import {Maybe} from '@possible/cassandra/src/types/consumer'
import {ViewPaymentMethodsSubsets} from 'src/products/general/GeneralPaymentMethods/ViewPaymentMethods/ViewPaymentMethods.types'
import {MakePaymentPaymentMethod} from 'src/products/general/MakePayment/MakePayment.types'
import {formatMaskNonBreaking} from 'src/lib/user/utils'
import i18n from 'src/lib/localization/i18n'

export const getPaymentMethodFriendlyName = (
  paymentMethod?: ViewPaymentMethodsSubsets | MakePaymentPaymentMethod,
): Maybe<string> => {
  switch (paymentMethod?.__typename) {
    case 'AchPaymentMethod':
      return paymentMethod?.bankAccount?.friendlyName ?? null
    case 'DebitCardPaymentMethod':
      return paymentMethod?.card?.friendlyName ?? null
    default:
      return null
  }
}

export const getPaymentMethodMask = (
  paymentMethod?: ViewPaymentMethodsSubsets | MakePaymentPaymentMethod,
): Maybe<string> => {
  switch (paymentMethod?.__typename) {
    case 'AchPaymentMethod':
      return paymentMethod?.bankAccount?.mask ?? null
    case 'DebitCardPaymentMethod':
      return paymentMethod?.card?.mask ?? null
    default:
      return null
  }
}

export const getPaymentMethodLabel = (
  paymentMethod: ViewPaymentMethodsSubsets | MakePaymentPaymentMethod,
): string => {
  const friendlyName = getPaymentMethodFriendlyName(paymentMethod) ?? ''
  const accountMask = getPaymentMethodMask(paymentMethod) ?? ''
  const formattedMask = formatMaskNonBreaking(accountMask, false)
  const cardEnding = i18n.t('PaymentMethods:CardEndingIn', {card: accountMask})

  switch (paymentMethod.__typename) {
    case 'AchPaymentMethod':
      return `${friendlyName} (${formattedMask})`
    case 'DebitCardPaymentMethod':
      return `${friendlyName}\n${cardEnding}`
    default:
      return ''
  }
}
