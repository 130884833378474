import {EnvironmentType} from '@possible/cassandra'

import {DevModeEnvPath, DevModeFilePath} from 'src/lib/devMode/devMode.consts'
import Log from 'src/lib/loggingUtil'
import RNFS from 'src/lib/RNFS/RNFS'
import 'src/lib/devMode/devMode'

export type DevModeInfoType = {
  devMode?: boolean
}

export const devModeInfo: DevModeInfoType = {
  devMode: undefined,
}

export async function setDevMode(enableDevMode: boolean): Promise<void> {
  try {
    devModeInfo.devMode = enableDevMode
    if (enableDevMode) {
      await RNFS.writeFile(DevModeFilePath, 'dev_mode', 'utf8')
    } else {
      await RNFS.unlink(DevModeFilePath)
    }
  } catch (e) {
    Log.log('setDevMode error:', e)
  }
}

export async function readDevMode(): Promise<boolean> {
  try {
    if (devModeInfo.devMode !== undefined) {
      return devModeInfo.devMode
    }

    const statResult = await RNFS.stat(DevModeFilePath)
    devModeInfo.devMode = statResult.isFile()
    return devModeInfo.devMode
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return false
  }
}

export async function readEnv(): Promise<EnvironmentType | undefined> {
  let ret: EnvironmentType | undefined
  try {
    const statResult = await RNFS.stat(DevModeEnvPath)
    if (statResult.isFile()) {
      // eslint-disable-next-line no-type-assertion/no-type-assertion
      ret = (await RNFS.readFile(DevModeEnvPath, 'utf8')) as EnvironmentType
    }
  } catch (e) {
    Log.log('readEnv error:', e)
  }
  return ret
}

export async function setEnv(env: EnvironmentType | undefined): Promise<void> {
  try {
    if (env !== undefined && env !== EnvironmentType.NotSet) {
      await RNFS.writeFile(DevModeEnvPath, env, 'utf8')
    } else {
      await RNFS.unlink(DevModeEnvPath)
    }
  } catch (e) {
    Log.log('setEnv error:', e)
  }
}
